import * as amplitude from '@amplitude/marketing-analytics-browser';

export type AmplitudeProperty = amplitude.Types.ValidPropertyType | undefined;
export type AmplitudeDictionary = Record<string, AmplitudeProperty>;

type TrackablePropertyMap = [string, string];
type TrackablesTuple = TrackablePropertyMap[];

// Query string parameters to track and their corresponding Amplitude property names
const UTM_TO_AMP_PROP: TrackablesTuple = [
  ['utm_adgroup', 'utmAdGroup'],
  ['utm_bu', 'utmBu'],
  ['utm_network', 'utmNetwork'],
  ['ut_source', 'upgradeTriggerSource'],
  ['ut_source2', 'upgradeTriggerSource2'],
  ['ut_source3', 'upgradeTriggerSource3'],
  ['program', 'campaignId'],
];

// Cookies to track and their corresponding Amplitude property names
export const COOKIES_TO_AMP_PROP: TrackablesTuple = [
  ['ep201', 'userSMSessionId'],
  ['ep202', 'userSMPersistentId'],
  ['attr_multitouch', 'multiTouchCookieId'],
];

/**
 * Extracts certain UT parameters from the current URL and returns them
 * formatted for Amplitude event properties
 *
 * @returns A dictionary of the extracted parameters
 */
export function extractUTParameters(): AmplitudeDictionary {
  const retVal: AmplitudeDictionary = {};

  // Extract ut params and add them to eventData
  const urlParams = new URL(window.location.href).searchParams;
  UTM_TO_AMP_PROP.forEach(([queryStringParameter, amplitudeProperty]) => {
    if (urlParams.has(queryStringParameter)) {
      retVal[amplitudeProperty] = urlParams.get(queryStringParameter)!;
    }
  });

  return retVal;
}

/**
 * Removes @sm/metrics specific properties from event data
 *
 * @param eventData The event properties dictionary
 * @returns A shallow copy of the event data provided with some keys removed
 */
export function cleanEventData(
  eventData: AmplitudeDictionary
): AmplitudeDictionary {
  const retVal = { ...eventData };

  // remove values we don't want to pass along as event properties
  delete retVal.amplitudeEvent;
  delete retVal.amplitudeUserProperties;
  delete retVal.actionType;
  delete retVal.actionFlow;
  delete retVal.disableDA;

  return retVal;
}

/**
 * Returns a dictionary of page viewed event properties for page viewed events
 *
 * @param eventName The name of the event being sent
 * @param eventData
 * @returns An empty dict if not a page viewed event, otherwise properties for a page viewed event
 */
export function getPageViewedEventProperties(
  eventName: string
): AmplitudeDictionary {
  let retVal: AmplitudeDictionary = {};

  // we're only in the business of page view events
  if (eventName.indexOf('page viewed') > -1) {
    retVal = {
      urlPath: window.location.pathname,
      hostName: window.location.hostname,
      ...extractUTParameters(),
    };
  }

  return retVal;
}

/**
 * Cleans and decorates the event properties for a given event
 *
 * @param eventName The name of the event to be sent
 * @param eventData The provided properties of the event
 * @returns Prepped dictionary of event properties
 */
export function prepareEventProperties(
  eventName: string,
  eventData: AmplitudeDictionary
): AmplitudeDictionary {
  let retVal = cleanEventData(eventData);
  retVal = {
    ...getPageViewedEventProperties(eventName),
    ...retVal,
  };

  return retVal;
}

import { v4 as uuidv4 } from 'uuid';

import { prepareEventProperties } from './common';
import {
  DigitalDataEvent,
  MetricsTrackerConfigurationType,
} from '../../types/types';

export default function anonymousTrack(
  config: MetricsTrackerConfigurationType,
  event: DigitalDataEvent
) {
  const { amplitudeToken, user } = config;
  const { data } = event;
  const { amplitudeEvent, amplitudeUserProperties } = data;

  // Bail if no Amplitude token, admin impersonation, or no amplitude event in data
  if (
    !amplitudeToken ||
    user?.session?.isAdminImpersonation ||
    !amplitudeEvent
  ) {
    return;
  }

  const userProperties = amplitudeUserProperties
    ? { ...amplitudeUserProperties }
    : {};

  const eventData = prepareEventProperties(amplitudeEvent, data);
  const userId = !user.id || user.id === '1' ? '' : user.id;

  // if the user is logged out, mark this as an anonymized event
  if (!userId) {
    userProperties.isAnonymized = true;
  }

  // Generate unique uuid for device ID
  const deviceId = uuidv4();

  // Send the event via beacon to avoid any built in cookie Action
  try {
    navigator.sendBeacon(
      'https://api2.amplitude.com/2/httpapi',
      JSON.stringify({
        api_key: amplitudeToken,
        events: [
          {
            device_id: deviceId,
            user_id: userId,
            event_type: amplitudeEvent,
            event_properties: eventData,
            user_properties: {
              $set: userProperties,
            },
            ip_address: '$remote',
            user_agent: navigator.userAgent,
            language: navigator.language,
            platform: 'Web',
          },
        ],
      })
    );
  } catch (error) {
    console.error(error);
  }
}

import { getClientEnvironmentDetails } from '@sm/utils/dist/environment'; // fix for downstream tree-shaking
import { maskEmail } from '../helpers';
import { DigitalDataEvent, GTMEventObject } from '../types/types';

export default function gtmMetricsSubscriber(
  digitalDataEvent: DigitalDataEvent
) {
  const { data, name } = digitalDataEvent;
  const { actionType, actionFlow } = data;

  if (!actionType || !actionFlow) {
    return;
  }

  // flatten the data object
  const gtmDataLayer = { ...data, ...data.data };
  delete gtmDataLayer.data;

  const envDetails = getClientEnvironmentDetails();

  if (envDetails.isBrowser) {
    const pagePath = window.location.pathname;
    window.dataLayer = window.dataLayer || [];

    const eventObj: any = ({
      hasGDPRConsent,
      userId,
      packageId,
      email,
      countryCode,
    }: GTMEventObject): Object => ({
      PAGE_VIEW: {
        event: 'pageView',
        loadMarketingContainer: hasGDPRConsent,
        user_id: userId,
        package_id: packageId,
        email: maskEmail(email),
        country_code: countryCode,
      },
      VIRTUAL_PAGE_VIEW: {
        event: 'virtualPageView',
        virtualPageUrl: pagePath,
        virtualPageTitle: actionFlow,
      },
      GDPR_ACTION: {
        event: actionFlow,
        loadMarketingContainer: actionFlow !== 'denyConsent',
      },
    });

    const gtm = eventObj(gtmDataLayer)[actionType] || {
      event: name,
      ...gtmDataLayer,
    };

    if (Object.keys(gtm).length) {
      window.dataLayer.push(gtm);
    }
  }
}

import {
  DigitalData,
  DigitalDataEvent,
  MetricsTrackerType,
  MetricsTrackerConfigurationType,
  MetricsTrackerUpdateConfigurationType,
  MetricsTrackerRoot,
  MetricsTrackerConfigurationUserUpdateableType,
} from './types/types';
import USER_EVENTS from './types/enums';

import { pageViewMutation, maskEmail } from './helpers';
import getCookieValue from './utils';

const DEFAULT_USER_ID = '1';
const GLOBAL_KEY = 'sm_metrics_tracker_do_not_touch_this';

/**
 * Creates a new DigitalData object and populates default values for the user
 *
 * @param userId The ID of the current user
 * @returns A DigitalData object populated only with default values
 */
export function getCleanDigitalData(
  userId: string = DEFAULT_USER_ID
): DigitalData {
  return {
    page: {
      pageInfo: {
        pageId: '',
        attributes: {},
        pathname: '',
      },
    },
    events: [],
    components: [],
    user: {
      ep201: getCookieValue('ep201'),
      ep202: getCookieValue('ep202'),
      id: userId,
    },
  };
}

/**
 * Returns the MetricsTracker root object. If it doesn't exist, creates it.
 *
 * @param glob A reference to the global object that MetricsTrackerRoot will by saved to
 * @returns The MetricsTrackerRoot
 */
export function setupRoot(glob: Record<string, any>): MetricsTrackerRoot {
  if (!glob[GLOBAL_KEY]) {
    const root: MetricsTrackerRoot = {
      subscribers: [],
      automaticSubscribers: [],
      config: {
        user: { id: '', isAuthenticated: false },
        dataAnalyticsAPIPath: null,
        loggingAPIPath: null,
        country: 'GB',
        legacyWeb: '',
        gtmId: '',
      },
      digitalData: getCleanDigitalData(DEFAULT_USER_ID),
    };
    glob[GLOBAL_KEY] = root;
  }
  return glob[GLOBAL_KEY] as MetricsTrackerRoot;
}

/**
 * Returns the global MetricsTrackerRoot object
 *
 * @param glob A reference to the global object on which the MetricsTrackerRoot object exists
 * @returns
 */
export function getRoot(glob: Record<string, any>): MetricsTrackerRoot {
  if (!glob[GLOBAL_KEY]) {
    throw new Error(
      'Please initialize MetricsTracker with a userId before tracking events.'
    );
  }
  return glob[GLOBAL_KEY];
}

/**
 * Creates a MetricsTrackerType object with common level functionality that is wrapped
 * in scope by the provided global object reference.
 *
 * @param glob A reference to the global namespace that the metrics tracker will be added to
 * @returns A MetricsTrackerType object with it's context bound to the passed in `glob` ref
 */
export const createMetricsTracker = (
  glob: Record<string, any>
): MetricsTrackerType => {
  return {
    initialize(config: MetricsTrackerConfigurationType): MetricsTrackerRoot {
      throw new Error('initialize not implemented');
    },

    reset(): MetricsTrackerRoot {
      throw new Error('reset not implemented');
    },

    update(config: MetricsTrackerUpdateConfigurationType): MetricsTrackerRoot {
      // for some data points within an SPA, the configuration must be
      // updated post initialization
      const root = getRoot(glob);
      root.config = { ...root.config, ...config };
      return root;
    },

    /**
     * Convenience method to update parts of the user config that can be decorated post-initialization
     *
     * @param userConfig The updated user configuration
     */
    updateUserConfig(
      userConfigUpdate: MetricsTrackerConfigurationUserUpdateableType
    ): MetricsTrackerRoot {
      const root = getRoot(glob);
      root.config.user = { ...root.config.user, ...userConfigUpdate };
      return root;
    },

    getSubscribers() {
      const { subscribers } = getRoot(glob);
      return subscribers;
    },

    getAutomaticSubscribers() {
      const { automaticSubscribers } = getRoot(glob);
      return automaticSubscribers;
    },

    track(event: DigitalDataEvent) {
      const root = getRoot(glob);
      if (root.config.user.id === '') {
        throw new Error(
          'Please initialize MetricsTracker with a userId before tracking events.'
        );
      }
      const maskedEvent = event;

      // mask email addresses
      if (event?.data?.email) {
        maskedEvent.data.email = maskEmail(event.data.email);
      }

      if (event?.data?.data?.email) {
        maskedEvent.data.data.email = maskEmail(event.data.data.email);
      }

      if (USER_EVENTS[event.name]) {
        root.digitalData.events.push(event);

        if (USER_EVENTS.COMPONENT_ADD === event.name) {
          root.digitalData.components.push(event.data);
        }

        if (
          event.name === USER_EVENTS.PAGE_VIEW ||
          event.name === USER_EVENTS.VIRTUAL_PAGE_VIEW
        ) {
          root.digitalData = pageViewMutation(event, root.digitalData);
        }

        root.subscribers.forEach(subscriber => {
          subscriber(event, root.config);
        });
      }
    },

    automaticTrack(event: DigitalDataEvent) {
      const root = getRoot(glob);
      root.automaticSubscribers.forEach(subscriber => {
        subscriber(event, root.config);
      });
    },

    addSubscriber(
      subscriber: (
        digitalDataEvent: DigitalDataEvent,
        metricsTrackerConfig: MetricsTrackerConfigurationType
      ) => void
    ): void {
      /**
       * We always want to allow the subscribers to subscribe. If the user
       * did not accept GDPR, we don't fire events; hence, we never violate GDPR.
       * If, however, the user does allow tracking, we don't want to subscribers
       * to re-subscribe.
       */
      const root = getRoot(glob);
      root.subscribers.push(subscriber);
    },
    addAutomaticSubscriber(
      subscriber: (
        digitalDataEvent: DigitalDataEvent,
        metricsTrackerConfig: MetricsTrackerConfigurationType
      ) => void
    ): void {
      const root = getRoot(glob);
      root.automaticSubscribers.push(subscriber);
    },
    getConfig(): MetricsTrackerConfigurationType {
      return getRoot(glob).config;
    },
    getDigitalData(): DigitalData {
      return getRoot(glob).digitalData;
    },
    isInitialized: false,
  };
};

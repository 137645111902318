enum USER_EVENTS {
  PAGE_VIEW = 'PAGE_VIEW', // Page view
  VIRTUAL_PAGE_VIEW = 'VIRTUAL_PAGE_VIEW', // SPA page view
  COMPONENT_ADD = 'COMPONENT_ADD', // Component added to the page
  COMPONENT_ERROR = 'COMPONENT_ERROR', // Component error (messages, data load, etc.)
  DATA_FETCH_ERROR = 'DATA_FETCH_ERROR', // Data fetch error
  ELEMENT_INTERACTION = 'ELEMENT_INTERACTION', // Element interaction (click, swipe, etc.)
  GDPR_ACTION = 'GDPR_ACTION', // Action taken
  NAVIGATION = 'NAVIGATION',
  USER_METADATA = 'USER_METADATA', // Adding information about the user
}

export enum USER_SUBSCRIPTION_STATUS {
  /**
   * The current user has never had a paid subscription
   */
  NEW = 'new',

  /**
   * The current user once had a paid subscription, but
   * currently does not
   */
  CHURNED = 'churned',

  /**
   * The current user has an active paid subscription
   */
  ACTIVE = 'active',
}

export default USER_EVENTS;

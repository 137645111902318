import {
  DigitalDataEvent,
  MetricsTrackerConfigurationType,
} from '../types/types';
import SMAmplitude from './amplitude';
import anonymousTrack from './amplitude/anonymous';

export const createAmplitudeSubscriber = (initialMetricsTrackerConfig?: MetricsTrackerConfigurationType) => {
  let smAmplitude: SMAmplitude | null = null;

  // pre-initialize the amplitude object if we were initialized with a config
  // and are not in forced anonymous mode
  if (initialMetricsTrackerConfig && !initialMetricsTrackerConfig.forceAnonymous) {
    smAmplitude = new SMAmplitude(initialMetricsTrackerConfig);
  }

  return (
    digitalDataEvent: DigitalDataEvent,
    metricsTrackerConfig: MetricsTrackerConfigurationType
  ) => {
    if (metricsTrackerConfig.forceAnonymous) {
      // Call track() for anonymous users (no need to initialize)
      anonymousTrack(metricsTrackerConfig, digitalDataEvent);
    } else {
      // Initialize (if needed) and then track for regular, non anonymous users
      if (!smAmplitude) {
        smAmplitude = new SMAmplitude(metricsTrackerConfig);
      }
      smAmplitude.track(digitalDataEvent);
    }
  };
};

const AmplitudeSubscriberSingleton = (() => {
  return createAmplitudeSubscriber();
})();

export default AmplitudeSubscriberSingleton;

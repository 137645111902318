import getCookieValue from '../../utils';

type OneTrustCookieInformation = {
  hasOneTrustCookie: boolean;
  keyValues: Record<string, string | boolean | undefined>;
  groups: Record<string, boolean>;
};

type Fides = {
  consent?: Record<string, boolean>;
};

export type FidesWindow = Window &
  typeof globalThis & {
    Fides?: Fides;
  };

const ONETRUST_COOKIE = 'OptanonConsent';
const ONETRUST_FUNCTIONAL_COOKIE_GROUP = 'C0003';

/**
 * Checks for and parses the information stored in the OneTrust cookie
 *
 * @returns Whether the OneTrust cookie exists and the information stored within it
 */
export function parseOneTrustCookie(): OneTrustCookieInformation {
  const cookieValue = getCookieValue(ONETRUST_COOKIE, '');
  const retVal: OneTrustCookieInformation = {
    hasOneTrustCookie: !!cookieValue,
    keyValues: {},
    groups: {},
  };

  if (retVal.hasOneTrustCookie) {
    cookieValue.split('&').forEach(token => {
      const keyVal = token.split('=').map(piece => decodeURIComponent(piece));
      const key = keyVal.shift();
      const value = keyVal.length ? keyVal.shift() : true;
      if (key) {
        retVal.keyValues[key] = value;
      }
    });

    // fix up groups to be cleaner
    if (typeof retVal.keyValues.groups === 'string') {
      retVal.keyValues.groups.split(',').forEach(group => {
        const tokens = group.split(':');
        retVal.groups[tokens[0]] = tokens[1] === '1';
      });
    }
  }

  return retVal;
}

/**
 * Checks for cookie consent via the OneTrust banner
 *
 * @returns Whether functional cookies are blocked by OneTrust
 */
const blockedByOneTrust = (): boolean => {
  const { hasOneTrustCookie, groups: oneTrustGroups } = parseOneTrustCookie();

  return !(
    hasOneTrustCookie &&
    oneTrustGroups[ONETRUST_FUNCTIONAL_COOKIE_GROUP] === true
  );
};

/**
 * Checks for cookie consent via the Fides banner
 *
 * @returns Whether functional cookies are blocked by Ethyca Fides
 */
const blockedByFides = (): boolean => {
  return !(window as FidesWindow).Fides?.consent?.functional;
};

/**
 * Returns whether or not functional cookies have been consented to via the cookie
 * consent banner, allowing for multiple cookie consent providers.
 *
 * @param overrideCookieConsent Allow cookie consent to be overridden by the consuming application.
 *                              This is largely for internal tools.
 * @returns
 */
const blockedByCookieConsent = (overrideCookieConsent = false) => {
  if (overrideCookieConsent) {
    return false;
  }

  // we should only have one cookie consent provider at any given time, however we're
  // aiming to seamlessly bridge the gap. since both "blockedBy" methods fall back to blocked
  // in the event that their respective provider hasn't yet been initialized, we'll get a
  // double `true` (blocked) when:
  // - neither provider has initialized their code yet; we need to wait
  // - one provider hasn't initialized because they don't exist, and the other is stating
  //   that functional cookies are explicitly allowed
  // in the event that either of these methods returns false, that means the provider has
  // done its thing and that functional cookies are allowed per that provider's rules.
  //
  // ...this has been eleven lines of comment to over explain the `&&` operator below...
  return blockedByOneTrust() && blockedByFides();
};

export default blockedByCookieConsent;

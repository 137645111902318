import {
  DigitalDataEvent,
  MetricsTrackerConfigurationType,
} from '../types/types';

export default function loggingMetricsSubscriber(
  digitalDataEvent: DigitalDataEvent,
  metricsTrackerConfig: MetricsTrackerConfigurationType
) {
  const eventData = JSON.parse(JSON.stringify(digitalDataEvent.data));
  const logEventData = eventData ? eventData.log : null;
  const { loggingAPIPath } = metricsTrackerConfig;
  if (
    typeof loggingAPIPath !== 'undefined' &&
    typeof logEventData !== 'undefined' &&
    logEventData !== null &&
    loggingAPIPath
  ) {
    delete eventData.log;
    const bodyRequestObject = { data: eventData, ...logEventData };
    fetch(loggingAPIPath, {
      method: 'POST',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify(bodyRequestObject),
    });
  }
}

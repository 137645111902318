export default function getCookieValue(
  cname: string,
  defaultValue?: string
): string {
  // `getClientEnvironmentDetails` could provide this information, but imports
  // the "bowser" package to decorate a bunch of details we don't need (that's ~5k minified)
  // As such, we'll just lift the line we actually want:
  // https://github.com/mntv-webplatform/smpackages/blob/master/packages/utils/src/environment/index.ts#L83
  const isBrowser = typeof window === 'object';
  if (!isBrowser) return '';
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return defaultValue || '';
}

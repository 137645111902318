import { DigitalData, DigitalDataEvent } from './types/types';
import USER_EVENTS from './types/enums';

export function cloneDigitalData(digitalData: DigitalData): DigitalData {
  return JSON.parse(JSON.stringify(digitalData));
}

export function pageViewMutation(
  event: DigitalDataEvent,
  digitalData: DigitalData
): DigitalData {
  if (
    event.name !== USER_EVENTS.PAGE_VIEW &&
    event.name !== USER_EVENTS.VIRTUAL_PAGE_VIEW
  ) {
    return digitalData;
  }

  const newDigitalData: DigitalData = cloneDigitalData(digitalData);

  const { pageId, pathname, attributes } = event.data;

  newDigitalData.page.pageInfo = { pageId, pathname, attributes };

  return newDigitalData;
}

export function maskString(str: string | undefined) {
  if (!str) return '';
  if (str.length === 1) return '*';
  if (str.length === 2) return '**';

  return str.replace(
    /^(.)(.*)(.)$/,
    (_, a, b, c) => a + b.replace(/./g, '*') + c
  );
}

export function maskEmail(email: string | undefined) {
  if (!email) return '';

  return email.replace(/^(.*)(@.*)$/, (_, a, b) => a.replace(/./g, '*') + b);
}

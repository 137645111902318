import * as amplitude from '@amplitude/marketing-analytics-browser';
import blockedByCookieConsent from './consent';
import anonymousTrack from './anonymous';
import { COOKIES_TO_AMP_PROP, prepareEventProperties } from './common';
import {
  DigitalDataEvent,
  MetricsTrackerConfigurationType,
} from '../../types/types';
import getCookieValue from '../../utils';

// the amount of time to wait before rechecking if we can initialize
const INITIALIZE_RECHECK_DELAY = 100;
// the amount of times to attempt re-initialization before falling back
// to sending events anonymously
const INITIALIZE_RECHECK_MAX = 20;
const DEFAULT_AMPLITUDE_CONFIG: amplitude.Types.BrowserOptions = {
  trackingOptions: {
    ip_address: false,
  },
  transport: amplitude.Types.TransportType.SendBeacon,
  minIdLength: 1,
};

const conditionallyAddKey = (
  keyName: string,
  value: any,
  out: Record<string, any>
) => {
  if (value) {
    // the whole point of this method is to populate a single object with props,
    // so passing that object around by reference is the most performant
    // eslint-disable-next-line no-param-reassign
    out[keyName] = value;
  }
};

/**
 * Attempts to read the Amplitude device ID from cookie
 *
 * @param apiToken The Amplitude API token
 * @returns The cookie device ID or undefined if it can't be found
 */
const getDeviceIdFromCookie = (apiToken: string): string | undefined => {
  try {
    const amplitudeCookieName = `AMP_${apiToken.substring(0, 10)}`;
    const amplitudeCookie = getCookieValue(amplitudeCookieName);
    const cookieData = JSON.parse(decodeURIComponent(atob(amplitudeCookie)));
    return cookieData?.deviceId;
  } catch (err) {
    return undefined;
  }
};

export default class SMAmplitude {
  private userProps: Record<string, amplitude.Types.ValidPropertyType> = {};

  private config: MetricsTrackerConfigurationType;

  private amplitudeSdkInitialized: boolean = false;

  private queuedEvents: DigitalDataEvent[] = [];

  private recheckCookieAttempts: number = 0;

  /**
   * Returns whether the configuration is valid for sending events
   */
  get canSendEvents(): boolean {
    const { amplitudeToken, user } = this.config;
    return (
      // we need an amplitude token...
      !!amplitudeToken &&
      // ...to not be impersonating a user...
      !user?.session?.isAdminImpersonation
    );
  }

  /**
   * Whether the use of functional cookies is allowed or not
   */
  get canUseFunctionalCookies(): boolean {
    return !blockedByCookieConsent(this.config.overrideOneTrust);
  }

  /**
   * Returns whether an event should be sent anonymously (i.e. we can't use functional cookies)
   */
  get shouldSendAnonymously(): boolean {
    return (
      !this.canUseFunctionalCookies &&
      this.recheckCookieAttempts >= INITIALIZE_RECHECK_MAX
    );
  }

  constructor(config: MetricsTrackerConfigurationType) {
    this.config = config;

    // only initialize in a browser
    if (typeof window === 'undefined') {
      return;
    }

    this.initialize();

    // we'll listen in on OneTrust/Fides's global event for if the consent changes. this way,
    // we don't have to worry about race conditions should this library initialize before
    // the OneTrust one is available
    window.addEventListener('consent.onetrust', () =>
      this.handleConsentChange()
    );
    window.addEventListener('FidesUpdated', () => this.handleConsentChange());
  }

  /**
   * Initializes the Amplitude SDK
   */
  initialize() {
    // if the configuration isn't there, just bail
    if (!this.canSendEvents) {
      return;
    }

    if (!this.amplitudeSdkInitialized && this.canUseFunctionalCookies) {
      const { amplitudeDeviceId, amplitudeSessionId, amplitudeToken, user } =
        this.config;
      const { isAuthenticated, id: userId, group } = user;
      const config = { ...DEFAULT_AMPLITUDE_CONFIG };
      conditionallyAddKey('deviceId', amplitudeDeviceId, config);
      conditionallyAddKey('sessionId', amplitudeSessionId, config);

      // if a device ID hasn't been passed explicitly, pull it out of cookies if we can
      // we're not guaranteed that Amplitude will read that value from its own cookie...
      if (!config.deviceId) {
        config.deviceId = getDeviceIdFromCookie(amplitudeToken as string);
      }

      try {
        // due to the `canSendEvents` check above, amplitudeToken is definitely not undefined
        amplitude.init(amplitudeToken as string, undefined, config);

        if (isAuthenticated) {
          amplitude.setUserId(userId);
          if (group?.membership?.groupId) {
            amplitude.setGroup('teamId', group?.membership?.groupId);
          }
        }

        this.hydrateDefaultUserProps();
        this.sendUserProperties();

        // add a bevy of event listeners to detect if the user is leaving the page in
        // some way. this will allow us to flush the event queue and prevent events
        // from being stuck in limbo. why so many events? because different browsers
        // act differently :|
        document.addEventListener('visibilitychange', () => this.flushCache());
        window.addEventListener('pagehide', () => this.flushCache());
        window.addEventListener('beforeunload', () => this.flushCache());

        // flush any events that may have queued before initialization
        this.flushCache();
      } catch (err) {
        // silently fail
      }
      this.amplitudeSdkInitialized = true;
    } else if (!this.canUseFunctionalCookies) {
      // retry checking for cookie consent a few times before falling back to sending
      // events anonymously
      this.recheckCookieAttempts += 1;
      if (!this.shouldSendAnonymously) {
        setTimeout(() => this.initialize(), INITIALIZE_RECHECK_DELAY);
      } else {
        // we're now in anonymous mode. flush any queued events
        this.flushCache();
      }
    }
  }

  /**
   * Sends a tracking event to Amplitude
   *
   * @param event The event and properties to send to Amplitude
   */
  track(event: DigitalDataEvent): void {
    const { data } = event;
    const { amplitudeEvent, amplitudeUserProperties } = data;

    // we don't have enough something. bail
    if (!amplitudeEvent || !this.canSendEvents) {
      return;
    }

    // if we're in anonymous mode, kick the event over that way
    if (this.shouldSendAnonymously) {
      anonymousTrack(this.config, event);
      return;
    }

    // if amplitude is disabled for any reason, stash the event for later
    // in the event it gets re-enabled so unsent events can get fired
    if (!this.canUseFunctionalCookies) {
      this.queuedEvents.push(event);
      return;
    }

    const eventData = prepareEventProperties(amplitudeEvent, data);

    // if extra user props were in this event, send those along
    if (amplitudeUserProperties) {
      this.sendUserProperties(amplitudeUserProperties);
    }

    amplitude.track(amplitudeEvent, { ...eventData });
  }

  /**
   * Pulls out the default user properties that should be sent upon initialization
   *
   * @param user The user configuration from initialization
   */
  private hydrateDefaultUserProps(): void {
    const { user } = this.config;

    const userProps: Record<string, amplitude.Types.ValidPropertyType> = {};

    // decorate logged in user information
    if (user.isAuthenticated) {
      conditionallyAddKey('languageId', user?.languageId, userProps);
      conditionallyAddKey('languageName', user?.languageName, userProps);
      conditionallyAddKey('languageCode', user?.languageCode, userProps);
      conditionallyAddKey('userPackageId', user?.package, userProps);
      conditionallyAddKey(
        'userPackageName',
        user?.packageCanonicalName || user?.packageName,
        userProps
      );
      conditionallyAddKey(
        'userSubscriptionStatus',
        user?.subscriptionStatus,
        userProps
      );
      conditionallyAddKey('dateJoined', user?.joinedAt, userProps);
      conditionallyAddKey('dateSubscribed', user?.subscribedAt, userProps);
      conditionallyAddKey('userRole', user.group?.membership?.type, userProps);
      conditionallyAddKey('userDataCenter', user?.dataCenter, userProps);
      conditionallyAddKey('userCountryCode', this.config.country, userProps);
      conditionallyAddKey(
        'isHipaaAccount',
        user?.hipaa?.isHipaaEnabled,
        userProps
      );
      conditionallyAddKey(
        'isEsdpAccount',
        user?.hipaa?.isSensitiveDataAccount,
        userProps
      );

      if (user.email) {
        const [, emailDomain] = user.email.split('@');
        if (emailDomain && emailDomain.indexOf('.') > -1) {
          userProps.emailDomain = emailDomain;
        }
      }
    }

    // decorate the user props with cookies to track
    COOKIES_TO_AMP_PROP.forEach(
      ([cookieName, propertyName]) =>
        (userProps[propertyName] = getCookieValue(cookieName))
    );

    this.userProps = userProps;
  }

  /**
   * Sends default user properties on initialization and additional user properties
   * any time via an Amplitude identify event.
   *
   * @param extraUserProps Additional user properties to send with the defaults
   */
  private sendUserProperties(
    extraUserProps?:
      | Record<string, amplitude.Types.ValidPropertyType>
      | undefined
  ) {
    // only send user props on initialization or if there are extra props to be sent
    if (!this.amplitudeSdkInitialized || extraUserProps) {
      const identify = new amplitude.Identify();
      const userProps = { ...this.userProps, ...(extraUserProps || {}) };
      Object.keys(userProps).forEach(key => {
        identify.set(key, userProps[key]);
      });
      amplitude.identify(identify);
    }
  }

  /**
   * Event handler for when a user changes OneTrust cookie consent
   */
  private handleConsentChange(): void {
    // if we are no longer able to use functional cookies and we weren't
    // sending events anonymously before, switch to sending events anonymously
    if (!this.canUseFunctionalCookies && !this.shouldSendAnonymously) {
      this.recheckCookieAttempts = INITIALIZE_RECHECK_MAX;
    }

    // if consent has been granted where it wasn't before, initialize everything and send any queued events
    if (!this.amplitudeSdkInitialized && !this.shouldSendAnonymously) {
      this.initialize();
    }

    // flush any queued events
    this.flushCache();
  }

  /**
   * Sends any queued events and flushes the Amplitude event cache
   */
  flushCache(): void {
    if (this.canSendEvents) {
      this.queuedEvents.forEach(event => this.track(event));
      this.queuedEvents = [];

      if (!this.shouldSendAnonymously) {
        amplitude.flush();
      }
    }
  }
}

import { getClientEnvironmentDetails } from '@sm/utils/dist/environment'; // fix for downstream tree-shaking
import { FC } from 'react';
import { MetricsTracker, USER_EVENTS } from '..';

type ComponentMetricsProviderPropType = {
  name: string;
  disableDA?: boolean;
  actionType: string;
  actionFlow: string;
  attributes?: any;
  children?: any;
};

const ComponentMetricsProvider: FC<ComponentMetricsProviderPropType> = ({
  name,
  attributes = null,
  disableDA = false,
  actionFlow,
  actionType,
  children,
}) => {
  const envDetails = getClientEnvironmentDetails();
  if (envDetails.isBrowser) {
    MetricsTracker.track({
      name: USER_EVENTS.COMPONENT_ADD,
      data: {
        componentName: name,
        attributes,
        disableDA,
        actionType,
        actionFlow,
      },
    });
  }
  return children;
};

export default ComponentMetricsProvider;

import { getClientEnvironmentDetails } from '@sm/utils/dist/environment'; // fix for downstream tree-shaking
import {
  MetricsTrackerType,
  MetricsTrackerConfigurationType,
  MetricsTrackerRoot,
  MetricsSubscriber,
} from './types/types';
import USER_EVENTS from './types/enums';
import {
  setupRoot,
  getRoot,
  getCleanDigitalData,
  createMetricsTracker,
} from './BaseMetricsTracker';

import dataAnalyticsMetricsSubscriber from './subscribers/DataAnalyticsMetricsSubscriber';
import loggingMetricsSubscriber from './subscribers/LoggingMetricsSubscriber';
import gtmMetricsSubscriber from './subscribers/GTMMeticsSubscriber';
import facebookPixelSubscriber from './subscribers/FacebookPixelSubscriber';
import { createAmplitudeSubscriber } from './subscribers/AmplitudeSubscriber';

const { isBrowser } = getClientEnvironmentDetails();
const glob: Record<string, any> = isBrowser ? window : global;

// Import/require only in the browser
const delegatedEvents = isBrowser ? require('delegated-events') : null;

/**
 * Returns the amplitude subscriber on the metrics root object, creating
 * it if it doesn't already exist
 *
 * @param root The metrics root object
 * @returns The instantiated Amplitude subscriber method
 */
function getAmplitudeSubscriber(root: MetricsTrackerRoot): MetricsSubscriber {
  if (!root._amplitudeSubscriber) {
    root._amplitudeSubscriber = createAmplitudeSubscriber(root.config);
  }

  return root._amplitudeSubscriber;
}

/**
 * Generates a string of event data that can be used in an HTML attribute
 *
 * @param eventData The name and data of the event
 * @returns A formatted strong of the eventData passed in
 */
export function generateMetricsAttribute(eventData: {
  name?: USER_EVENTS;
  data: Record<string, any>;
}): String {
  return JSON.stringify({
    name: eventData.name || USER_EVENTS.ELEMENT_INTERACTION,
    data: eventData.data,
  });
}

// we want to ensure that there is only ever one MetricsTracker created and exported
// from this package, so export the global var if it's there, otherwise create it
let _MetricsTracker: MetricsTrackerType | undefined = glob?.SM
  ?.MetricsTracker as MetricsTrackerType;
if (!_MetricsTracker) {
  _MetricsTracker = {
    // create the base object
    ...createMetricsTracker(glob),

    /**
     * Initializes the events and configuration of the MetricsTracker
     *
     * @override
     * @param config
     * @returns
     */
    initialize(config: MetricsTrackerConfigurationType): MetricsTrackerRoot {
      const root = setupRoot(glob);
      root.config = config;
      root.digitalData = getCleanDigitalData(config.user.id);

      // amplitude initializes itself specially, so we'll init the subscriber here for persistence reasons
      root._amplitudeSubscriber = getAmplitudeSubscriber(root);

      setupEvents(root);

      this.isInitialized = true;
      return root;
    },

    reset(): MetricsTrackerRoot {
      const root = getRoot(glob);
      root.digitalData = getCleanDigitalData(root.config.user.id);
      root.subscribers = [];
      root.automaticSubscribers = [];

      // Add the mandatory dataAnalytics subscriber
      this.addSubscriber(dataAnalyticsMetricsSubscriber);
      // Add the mandatory logging subscriber
      this.addSubscriber(loggingMetricsSubscriber);
      // Add Google Tag Manager dataLayer subscriber
      this.addSubscriber(gtmMetricsSubscriber);
      // Add Facebook Pixel subscriber
      this.addSubscriber(facebookPixelSubscriber);
      // Re-add the Amplitude Subscriber (no need to create a new one)
      this.addSubscriber(getAmplitudeSubscriber(root));

      return root;
    },
  };

  // toss out to the global namespace
  if (isBrowser) {
    window.SM = window.SM || {};
    window.SM.MetricsTracker = _MetricsTracker;
  }
}

function setupEvents(root: MetricsTrackerRoot) {
  if (!isBrowser) {
    return;
  }

  // only create one event listener on the document
  // this makes it safe for consumer apps to call initialize multiple times
  if (window?.SM?.MetricsTracker && !window.SM.MetricsTracker.isInitialized) {
    const { MetricsTracker } = window.SM;
    /**
     * Add on click event listener
     * Note - this is done in the getInstance method; hence, until this
     * method is called, the event handler would never be bound.
     */
    delegatedEvents.on(
      'click',
      '[data-sm-metrics]',
      function gce(event: MouseEvent) {
        if (event && event.currentTarget) {
          const metricsData = (event.currentTarget as HTMLElement).getAttribute(
            'data-sm-metrics'
          );
          if (metricsData) {
            const metricsDataJSON = JSON.parse(metricsData);
            const eventData = metricsDataJSON.data;

            MetricsTracker.track({
              name: metricsDataJSON.name || USER_EVENTS.ELEMENT_INTERACTION,
              data: eventData,
            });
          }
        }
      }
    );
    // Add the mandatory dataAnalytics subscriber
    MetricsTracker.addSubscriber(dataAnalyticsMetricsSubscriber);
    // Add the mandatory logging subscriber
    MetricsTracker.addSubscriber(loggingMetricsSubscriber);
    // Add Google Tag Manager dataLayer subscriber
    MetricsTracker.addSubscriber(gtmMetricsSubscriber);
    // Add Facebook Pixel subscriber
    MetricsTracker.addSubscriber(facebookPixelSubscriber);
    // Add Amplitude Subscriber
    MetricsTracker.addSubscriber(getAmplitudeSubscriber(root));

    setupAutomaticEvents(MetricsTracker);
  }
}

function setupAutomaticEvents(MetricsTracker: MetricsTrackerType) {
  const clickableLinks = [
    '.wds-button',
    '.wds-link',
    'a',
    'wds-switch__native',
    'wds-checkbox__native',
    'wds-radio__native',
    'wds-list__item',
    'wds-close',
    'wds-popout__trigger',
    'wds-has-menu',
    'wds-slider__handle',
    'wds-tab__tab-btn',
  ];

  delegatedEvents.on(
    'click',
    clickableLinks.join(','),
    function gce(event: MouseEvent) {
      if (!event.target) {
        return;
      }

      const name = event.type;
      const target = event.target as HTMLElement;
      const inputTarget = target as HTMLInputElement;

      MetricsTracker.automaticTrack({
        name: USER_EVENTS.ELEMENT_INTERACTION,
        data: {
          actionType: name,
          actionFlow: 'automatic',
          class: target.className,
          text: target.innerText,
          checked: inputTarget.checked || undefined,
          value: inputTarget.value || undefined,
        },
      });
    }
  );
}

export const MetricsTracker = _MetricsTracker;

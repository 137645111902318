import { useEffect, useMemo, FC } from 'react';
import { MetricsTracker, USER_EVENTS } from '..';

type SPARouteMetricsProviderProps = {
  pageId: string;
  legacyWeb: string;
  pathname: string;
  attributes?: Record<string, any>;
  GDPR: {
    hasGDPRConsent: boolean;
  };
  user: {
    id: string;
    package: string;
    email: string;
  };
  country?: string;
  children?: any;
};

const SPARouteMetricsProvider: FC<SPARouteMetricsProviderProps> = ({
  pageId,
  pathname,
  legacyWeb,
  attributes,
  GDPR,
  user,
  country,
  children,
}) => {
  const { hasGDPRConsent = false } = GDPR;
  const { id: userId = '1', package: packageId = '1', email = '' } = user;

  const metricsData = useMemo(() => {
    return {
      hasGDPRConsent,
      userId,
      packageId,
      email,
      countryCode: country,
    };
  }, [hasGDPRConsent, userId, packageId, email, country]);

  useEffect(() => {
    // update the global config
    MetricsTracker.update({
      pageId,
      legacyWeb,
    });

    /*
    // TODO: This shouldn't fire on intital render, but it needs to right now
    // to ensure all page metrics for SPA are accurately recorded. For example
    // in the SPA, PAGE_VIEW only knows about the SPA container, not the page within
    // the container. So the VIRTUAL_PAGE_VIEW is needed to track the actual page content
    */
    MetricsTracker.track({
      name: USER_EVENTS.VIRTUAL_PAGE_VIEW,
      data: {
        actionType: USER_EVENTS.VIRTUAL_PAGE_VIEW,
        actionFlow: pageId,
        pageId,
        attributes,
        pathname,
        data: metricsData,
      },
    });
  }, [pageId, legacyWeb, pathname, attributes, metricsData]);

  return children;
};

export default SPARouteMetricsProvider;

import {
  DigitalDataEvent,
  MetricsTrackerConfigurationType,
} from '../types/types';

export default function dataAnalyticsMetricsSubscriber(
  digitalDataEvent: DigitalDataEvent,
  metricsTrackerConfig: MetricsTrackerConfigurationType
) {
  // Verify that the required values are present and we're not being
  // bypassed before continuing. If not, exit quietly.
  if (
    digitalDataEvent.data.disableDA === true ||
    !digitalDataEvent.data.actionFlow ||
    !digitalDataEvent.data.actionType
  ) {
    return;
  }

  const eventData = JSON.parse(JSON.stringify(digitalDataEvent.data));
  const { dataAnalyticsAPIPath, legacyWeb } = metricsTrackerConfig;
  const eventMeta = {
    ui_event: '',
    ui_goal: '',
    url: '',
    origin: '',
    legacy_web: legacyWeb,
  };

  eventMeta.ui_event = eventData.actionType;
  eventMeta.ui_goal = eventData.actionFlow;
  eventMeta.url = window.location.href;
  eventMeta.origin = eventData.origin || 'user';
  delete eventData.actionType;
  delete eventData.actionFlow;
  delete eventData.origin;
  // FUTURE: The listener should implement experiments metrics.

  // Send the event to the data analytics api
  if (dataAnalyticsAPIPath) {
    fetch(dataAnalyticsAPIPath, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
      body: JSON.stringify({ data: eventData, meta: eventMeta }),
    });
  }
}

import { useEffect, useMemo, FC } from 'react';

import { MetricsTracker, USER_EVENTS } from '..';

type PageMetricsProviderPropType = {
  pageId: string;
  pathname?: string;
  attributes?: Record<string, any>;
  GDPR: {
    hasGDPRConsent: boolean;
  };
  user: {
    id: string;
    package: string;
    email: string;
    packageCanonicalName: string;
  };
  country?: string;
  children?: any;
};

const PageMetricsProvider: FC<PageMetricsProviderPropType> = ({
  pageId,
  pathname,
  attributes,
  GDPR,
  user,
  country,
  children,
}) => {
  const { hasGDPRConsent = false } = GDPR;
  const {
    id: userId = '1',
    package: packageId = '1',
    email = '',
    packageCanonicalName: packageName = 'undefined',
  } = user;
  const metricsData = useMemo(() => {
    return {
      actionType: USER_EVENTS.PAGE_VIEW,
      actionFlow: pageId,
      pathname,
      pageId,
      attributes,
      data: {
        hasGDPRConsent,
        userId,
        packageId,
        email,
        country,
        packageName,
      },
    };
  }, [
    pageId,
    pathname,
    attributes,
    hasGDPRConsent,
    userId,
    packageId,
    email,
    country,
    packageName,
  ]);

  useEffect(() => {
    MetricsTracker.track({
      name: USER_EVENTS.PAGE_VIEW,
      data: metricsData,
    });
  }, [metricsData]);

  return children;
};

export default PageMetricsProvider;

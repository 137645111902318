import { DigitalDataEvent } from '../types/types';
import USER_EVENTS from '../types/enums';

const whiteListedURLs = [/\/collect\/audience\/.*\/status/];

const isValidURL = (url: string) =>
  whiteListedURLs.some(pattern => url.match(pattern) !== null);

export default function facebookPixelSubscriber(
  digitalDataEvent: DigitalDataEvent
) {
  // eslint-disable-next-line global-require
  const ReactPixel = require('react-facebook-pixel');
  const { name, data, key } = JSON.parse(JSON.stringify(digitalDataEvent.data));
  const { name: actionType } = digitalDataEvent;
  if (isValidURL(window.location.href) && key) {
    ReactPixel.init(key);
    if (actionType === USER_EVENTS.USER_METADATA && name && data) {
      ReactPixel.track(name, data);
    }
    if (actionType === USER_EVENTS.PAGE_VIEW) ReactPixel.pageView();
  }
}
